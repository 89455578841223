import React, { useState } from "react";
import { Button, Col, Row, Form, Input } from 'antd';
import { LockOutlined, UserOutlined } from '@ant-design/icons';
import axios from "axios";
import { useNavigate } from 'react-router-dom'


const Login = () => {
    const navigate = useNavigate();

    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");

    const submit = async () => {
        //e.preventDefault();
        try {
            const res = await axios.post("http://localhost:8000/login", {
                email,
                password,
            });
            if (res.status === 200) {
                navigate("/components/dashboard");
            } else {
                console.log("invalid user");
            }

            console.log(res);
            // if (res.data === "exist") {
            //   history("/#/dashboard", { state: { id: email } });
            // } else if (res.data === "exist") {
            //   alert(res);
            // } 
        } catch (e) {
            //console.log(e.response.data);
            alert(e.response.data);
        }
    };

    return (
        <>
            <div className="login">
                <div className="login-container md:mx-5">
                    <Row gutter={40}>
                        {/* <Col md={12} xs={24}>
                            <img src="images/allen-global-logo.png" style={{ width: 200 }} alt='allen-global-logo' className="for-mobile" />
                            <h1 className="text-[#0F4275] text-2xl text-center font-medium mb-0">Student Login</h1>
                            <p className="text-[#8A8181] text-lg text-center font-light">All enrolled students can login here.</p>
                            <img src="images/login-img.png" alt='login-img' className="mb-5 md:mb-0" />
                        </Col> */}
                        <Col md={12} xs={24}>
                            <Col span={24}>
                                <div className="mb-7 flex justify-center">
                                    <img src="images/allen-global-logo.png" style={{ width: 200 }} alt='allen-global-logo' className="for-web" />
                                </div>
                            </Col>
                            <Form
                                name="normal_login"
                                //form={form}
                                className="login-form"
                                initialValues={{
                                    remember: true,
                                }}
                                onFinish={submit}

                            //onFinishFailed={(errorInfo) => console.log('validation-error:', errorInfo)}
                            >
                                <Col md={24} xs={24} lg={22}>
                                    <Form.Item
                                        name="email"
                                        rules={[
                                            {
                                                required: true,
                                                message: 'Please enter your username !',
                                            },
                                        ]}
                                    >
                                        <Input suffix={<UserOutlined className="site-form-item-icon" />}
                                            placeholder="Username"
                                            type="text"
                                            onChange={(e) => {
                                                setEmail(e.target.value);
                                            }}
                                        />
                                    </Form.Item>
                                </Col>
                                <Col md={24} xs={24} lg={22}>
                                    <Form.Item
                                        name="password"
                                        rules={[
                                            {
                                                required: true,
                                                message: 'Please input your Password!',
                                            },
                                        ]}
                                    >
                                        <Input
                                            suffix={<LockOutlined
                                                className="site-form-item-icon" />}
                                            type="password"
                                            placeholder="Enter Password"
                                            onChange={(e) => {
                                                setPassword(e.target.value);
                                            }}
                                        />
                                    </Form.Item>
                                </Col>

                                <Col md={24} xs={24} lg={22}>
                                    <Form.Item>
                                        <Button type="primary"
                                            // onClick={handleApi}
                                            // to={"/student-portal/general-enquiry"}
                                            htmlType="submit"
                                            className="login-btn text-xl text-[#0D3761] bg-[#FCC222]"
                                        >
                                            Login
                                        </Button>
                                    </Form.Item>
                                </Col>
                            </Form>
                        </Col>
                    </Row>

                </div>
            </div>
        </>
    )
}

export default Login