import React, { useState } from "react";
import { Button, Layout, Table, } from 'antd';
//import axios from "axios";
import AppLayout from "./app-layout";
import { useNavigate } from 'react-router-dom'
const { Content } = Layout;

const Dashboard = () => {

    const navigate = useNavigate();

    const handleClick = () => {
        navigate('/components/page')
    }

    const columns = [
        {
            title: 'Title',
            dataIndex: 'title',
            key: 'title',
            //render: (text) => <a>{text}</a>,
        },
        {
            title: 'Slug',
            dataIndex: 'slug',
            key: 'slug',
        },
        {
            title: 'Heading',
            dataIndex: 'heading',
            key: 'heading',
        },

        {
            title: 'Action',
            key: 'action',
            // render: (_, record) => (
            //     <Space size="middle">
            //         <a>Invite {record.name}</a>
            //         <a>Delete</a>
            //     </Space>
            // ),
        },
    ];

    const data = [];

    return (
        <>
            <AppLayout>
                <Content
                    className="site-layout-background"
                    style={{
                        margin: '20px 16px 24px',
                        padding: 24,
                        background: '#fff',
                        width: '100%'
                    }}
                >
                    <h1>Pages</h1>
                    <Button className="bg-[#F8A30C] mb-5" style={{ float: 'right' }} type="primary"
                        onClick={handleClick}
                    >Add New</Button>
                    <div>
                        <Table columns={columns} dataSource={data} />
                        {/* {data.length === 0 && <p>No data available</p>} */}

                    </div>
                </Content>
            </AppLayout>

        </>
    )
}

export default Dashboard