import React, { useState } from 'react';
import { Button, Layout, Table, Form, Col, Row, Input, Select } from 'antd';
import AppLayout from "./app-layout";
import TextArea from 'antd/es/input/TextArea';

const { Content } = Layout;



const Page = () => {
    const [selectedLayout, setSelectedLayout] = useState('');

    const handleLayout = (value) => {
        setSelectedLayout(value)
    }


    return (
        <>
            <AppLayout>
                <Content
                    className="site-layout-background"
                    style={{
                        margin: '20px 16px 24px',
                        padding: 24,
                        background: '#fff',
                        width: '100%'
                    }}
                >
                    <div>
                        <Form>
                            <Row>
                                <Col md={24}>

                                    <Form.Item
                                        label="Layout"
                                        wrapperCol={{ span: 12 }}
                                        labelCol={{ span: 2 }}
                                        name="layout" className='py-2.5'>
                                        <Select

                                            placeholder="Select"
                                            onChange={handleLayout}
                                            name="layout"
                                            style={{ width: '100%' }}

                                        >
                                            <Select.Option value="Layout 1">Layout 1</Select.Option>
                                            <Select.Option value="Layout 2">Layout 2</Select.Option>
                                            <Select.Option value="Layout 3">Layout 3</Select.Option>
                                        </Select>
                                    </Form.Item>
                                    <Col md={24}>

                                        <Form.Item
                                            label="Title"
                                            wrapperCol={{ span: 8 }}
                                            labelCol={{ span: 2 }}
                                            name="title" className='py-2.5'>
                                            <Input />
                                        </Form.Item>

                                    </Col>
                                    <Col md={24}>

                                        <Form.Item
                                            label="Slug"
                                            wrapperCol={{ span: 12 }}
                                            labelCol={{ span: 2 }}
                                            name="slug" className='py-2.5'>
                                            <Input />
                                        </Form.Item>
                                    </Col>

                                    <Col md={24}>
                                        <Form.Item
                                            label="URL"
                                            wrapperCol={{ span: 12 }}
                                            labelCol={{ span: 2 }}
                                            name="url" className='py-2.5'>
                                            <Input />
                                        </Form.Item>
                                    </Col>

                                    <Col md={24}>
                                        <Form.Item
                                            label="Heading"
                                            wrapperCol={{ span: 12 }}
                                            labelCol={{ span: 2 }}
                                            name="heading" className='py-2.5'>
                                            <Input />
                                        </Form.Item>
                                    </Col>


                                    <Col md={24}>
                                        <Form.Item
                                            label="Banner"
                                            wrapperCol={{ span: 12 }}
                                            labelCol={{ span: 2 }}
                                            name="heading" className='py-2.5'>
                                            <input type="file" id="myfile" name="banner" />
                                        </Form.Item>
                                    </Col>

                                    <Col md={24}>
                                        <Form.Item
                                            label="Description"
                                            wrapperCol={{ span: 12 }}
                                            labelCol={{ span: 2 }}
                                            name="description" className='py-2.5'>
                                            <TextArea />
                                        </Form.Item>
                                    </Col>

                                    {(selectedLayout === 'Layout 1' || selectedLayout === 'Layout 2') && (
                                        <Col md={24}>
                                            <Form.Item
                                                label="Paragraph"
                                                wrapperCol={{ span: 12 }}
                                                labelCol={{ span: 2 }}
                                                name="paragraph" className='py-2.5'
                                            >
                                                <TextArea />
                                            </Form.Item>
                                        </Col>
                                    )}

                                </Col>
                            </Row>

                        </Form>
                    </div>

                </Content>

            </AppLayout>
        </>
    )

}


export default Page