import React from 'react';


const Footer = () => {
    return (
        <>
            <section class="footer-section">
                <div class="subscribe">
                    <div class="container">
                        <div class="subscribe-box">
                            <div class="row">
                                <div class="col-lg-6 col-md-12 col-12">
                                    <span>Newsletter</span>
                                    <h4>Subscribe us for global engagement</h4>
                                </div>
                                <div class="col-lg-6 col-md-12 col-12">
                                    <div class="subscribe-form">
                                        <form>
                                            <div class="input-group">
                                                <input class="form-control" name="email" id="email" type="email" placeholder="Email Address"
                                                    required />
                                                <button class="btn btn-info btn-lg" type="submit">Submit</button>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <footer>
                    <div class="container">
                        <div class="row">
                            <div class="col-lg-4 col-12">
                                <div class="footer-logo">
                                    <img src="/images/footer-logo.png" alt="" />
                                </div>
                                <ul class="footer-address">
                                    <li><i class="fa fa-map-marker" aria-hidden="true"></i> 1st Floor, PMC Building 2, CP 13 - Indra Vihar,
                                        Kota (Raj.) Pin: 324005, India</li>
                                    <li><i class="fa fa-envelope" aria-hidden="true"></i> <a href="mailto:globalstudies@allen.ac.in"
                                        target="_blank"> globalstudies@allen.ac.in</a></li>
                                    <li><i class="fa fa-phone" aria-hidden="true"></i> <a href="tel:+9177268-52446"> +91-7726852446</a>, <a
                                        href="tel:+919513766077"> +91-9513766077</a></li>
                                    <li><i class="fa fa-whatsapp" aria-hidden="true"></i> <a href="https://wa.me/+917726852446"
                                        target="_blank"> WhatsApp</a></li>
                                </ul>
                                <div class="footer-social">
                                    <a href="https://m.facebook.com/ALLENGlobalStudiesDivision/" target="_blank"><i class="fa fa-facebook"
                                        aria-hidden="true"></i></a>
                                    <a href="https://instagram.com/allenglobalstudies?igshid=YmMyMTA2M2Y=" target="_blank"><i
                                        class="fa fa-instagram" aria-hidden="true"></i></a>
                                    <a href="https://www.linkedin.com/company/allen-global-studies-division/" target="_blank"><i
                                        class="fa fa-linkedin" aria-hidden="true"></i></a>
                                    <a href="https://twitter.com/AllenAGSD?t=1hHrBUecUfRe61rZsN6N3w&amp;s=08" target="_blank"><i
                                        class="fa fa-twitter" aria-hidden="true"></i></a>
                                    <a href="https://youtube.com/channel/UCuI7VRMwOQYyMm0zFYF6ZDg" target="_blank"><i class="fa fa-youtube"
                                        aria-hidden="true"></i></a>
                                </div>
                            </div>
                            <div class="col-lg-2 col-md-4 col-12">
                                <div class="footer-links">
                                    <h5>Study Destination</h5>
                                    <ul>
                                        <li><a href="https://www.allenglobalstudies.com/countries/study-in-usa/">Study in USA</a></li>
                                        <li><a href="https://www.allenglobalstudies.com/countries/study-in-canada/">Study in Canada</a></li>
                                        <li><a href="https://www.allenglobalstudies.com/countries/study-in-uk/">Study in UK</a></li>
                                        <li><a href="https://www.allenglobalstudies.com/countries/study-in-australia/">Study in Australia</a>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                            <div class="col-lg-2 col-md-4 col-12">
                                <div class="footer-links">
                                    <h5>Our Best Services</h5>
                                    <ul>
                                        <li><a href="https://www.allenglobalstudies.com/our-services/career-counselling/">Career Counselling</a>
                                        </li>
                                        <li><a href="https://www.allenglobalstudies.com/our-services/test-preparation/">Test Preparation</a>
                                        </li>
                                        <li><a href="https://www.allenglobalstudies.com/our-services/document-preparation/">Document
                                            Preparation</a></li>
                                        <li><a href="https://www.allenglobalstudies.com/our-services/application-process/">Application
                                            Process</a></li>
                                        <li><a href="https://www.allenglobalstudies.com/our-services/student-visa-assistance/">Student VISA
                                            Assistance</a></li>
                                    </ul>
                                </div>
                            </div>
                            <div class="col-lg-2 col-md-4 col-12">
                                <div class="footer-links">
                                    <h5>Test Preparation</h5>
                                    <ul>
                                        <li><a href="https://www.allenglobalstudies.com/test-preparation/sat-exam/">SAT Exam</a></li>
                                        <li><a href="https://www.allenglobalstudies.com/test-preparation/act-exam/">ACT Exam</a></li>
                                    </ul>
                                </div>
                            </div>
                            <div class="col-lg-2 col-md-4 col-12">
                                <div class="footer-links">
                                    <h5>Useful Links</h5>
                                    <ul>
                                        <li><a href="https://www.allenglobalstudies.com/about-us/">About ALLEN</a></li>
                                        <li><a href="https://www.allenglobalstudies.com/long-term-courses-2023-24/">Long Term Courses</a></li>
                                        <li><a href="https://www.allenglobalstudies.com/short-term-courses-2023-24/">Short Term Courses</a></li>
                                    </ul>
                                </div>
                            </div>
                            <div class="col-lg-4 col-md-4 col-12 mt-4">
                                <div class="footer-links">
                                    <h5>Legal</h5>
                                    <ul>
                                        <li><a href="https://www.allenglobalstudies.com/terms-conditions/">Terms &amp; Conditions</a></li>
                                    </ul>
                                </div>
                            </div>
                            <div class="col-lg-8 col-md-12 col-12 mt-4">
                                <div class="footer-links">
                                    <h5>Our Counselling Offices in India</h5>
                                    <ul class="offices">
                                        <li>Kota</li>
                                        <li>Bengaluru</li>
                                        <li>Pune</li>
                                        <li>Surat</li>
                                        <li>Mohali</li>
                                        <li>Ahmedabad</li>
                                        <li>Mumbai</li>
                                        <li>Indore</li>
                                        <li>Bhubaneswar</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </footer>

                <div class="footer-copyright">
                    <div class="container">
                        ALLEN Career Institute Pvt. Ltd. © All Rights Reserved.
                    </div>
                </div>
            </section>
        </>
    )
}

export default Footer