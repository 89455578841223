import React from 'react';
import '../css/style.css';
import '../css/responsive.css'

const Header = () => {
    return (
        <>
            <div class="sticky-top">
                <header class="bg-white">
                    <div class="header-top">
                        <div class="container">
                            <ul class="header-ul">
                                <li><a href="https://www.allen.ac.in/apps2324/ccp/register?leadtype=AGSD" target="_blank" class="enroll-now">Enroll Now</a></li>
                                <li><a href="https://www.allen.ac.in/apps2324/ccp/Login?leadtype=AGSD" target="_blank" class="login">Login</a></li>
                                <li><a href="https://www.allen.ac.in/apps2324/formstatus/login" target="_blank" class="fee-deposition"><span>Course</span> Fee Deposit</a></li>
                            </ul>
                        </div>
                    </div>
                    <div class="container">
                        <div class="row">
                            <div class="col-lg-3 col-6 landing-logo-section">
                                <a href="https://www.allenglobalstudies.com/agsd/" class="logo img-fluid-link" rel="home"><img width="350" height="106" src="https://www.allenglobalstudies.com/agsd/wp-content/uploads/2022/06/allen-global-logo.png" class="logo img-fluid" alt="ALLEN Global Studies Division Logo" decoding="async" /></a>                        </div>
                            <div class="col-lg-9 col-5 mobile-hide-section">
                                <ul class="header-ul">
                                    <li><a href="mailto:globalstudies@allen.ac.in"><i class="fa fa-envelope"></i> globalstudies@allen.ac.in</a></li>
                                    <li><a href="tel:+917726852446"><i class="fa fa-phone-alt"></i> +917726852446</a>, <a href="tel:+919513766077" class="mob-hide"> +91-9513766077</a></li>
                                    <li><a href="https://wa.me/+917726852446" target="_blank"><i class="fa fa-whatsapp whatsapp-color"></i> WhatsApp</a></li>
                                </ul>
                            </div>
                            <div class="col-lg-9 col-6 desktop-hide-section">
                                <ul class="header-ul">
                                    <li><a href="tel:+917726852446"><i class="fa fa-phone" aria-hidden="true"></i> </a></li>
                                    <li><a href="https://wa.me/+917726852446" target="_blank"><i class="fa fa-whatsapp whatsapp-color"
                                        aria-hidden="true"></i> </a></li>
                                    <li>
                                        <button class="navbar-toggler collapsed" type="button" data-bs-toggle="collapse"
                                            data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false"
                                            aria-label="Toggle navigation">
                                            <span class="navbar-toggler-icon"></span>
                                        </button>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <nav class="navbar navbar-expand-lg navbar-light bg-light">
                        <div class="container">
                            <div class="collapse navbar-collapse" id="navbarSupportedContent">
                                <ul id="menu-main-menu" class="navbar-nav me-auto mb-2 mb-lg-0" itemscope
                                    itemtype="http://www.schema.org/SiteNavigationElement">
                                    <li id="menu-item-221"
                                        class="menu-item menu-item-type-custom menu-item-object-custom menu-item-has-children dropdown menu-item-221 nav-item">
                                        <a href="#" data-toggle="dropdown" aria-expanded="false"
                                            class="dropdown-toggle nav-link" id="menu-item-dropdown-221"><span
                                                itemprop="name">Study Abroad</span></a>
                                        <ul class="dropdown-menu" aria-labelledby="menu-item-dropdown-221">
                                            <li id="menu-item-328"
                                                class="menu-item menu-item-type-post_type menu-item-object-countries menu-item-328 nav-item">
                                                <a itemprop="url"
                                                    href="https://www.allenglobalstudies.com/agsd/countries/study-in-usa/"
                                                    class="dropdown-item"><span itemprop="name">Study in USA</span></a></li>
                                            <li id="menu-item-325"
                                                class="menu-item menu-item-type-post_type menu-item-object-countries menu-item-325 nav-item">
                                                <a itemprop="url"
                                                    href="https://www.allenglobalstudies.com/agsd/countries/study-in-canada/"
                                                    class="dropdown-item"><span itemprop="name">Study in Canada</span></a></li>
                                            <li id="menu-item-324"
                                                class="menu-item menu-item-type-post_type menu-item-object-countries menu-item-324 nav-item">
                                                <a itemprop="url"
                                                    href="https://www.allenglobalstudies.com/agsd/countries/study-in-uk/"
                                                    class="dropdown-item"><span itemprop="name">Study in UK</span></a></li>
                                            <li id="menu-item-1169"
                                                class="menu-item menu-item-type-post_type menu-item-object-countries menu-item-1169 nav-item">
                                                <a itemprop="url"
                                                    href="https://www.allenglobalstudies.com/agsd/countries/study-in-australia/"
                                                    class="dropdown-item"><span itemprop="name">Study in Australia</span></a>
                                            </li>
                                            <li id="menu-item-3473"
                                                class="menu-item menu-item-type-post_type menu-item-object-countries menu-item-3473 nav-item">
                                                <a itemprop="url"
                                                    href="https://www.allenglobalstudies.com/agsd/countries/study-in-singapore/"
                                                    class="dropdown-item"><span itemprop="name">Study in Singapore</span></a>
                                            </li>
                                        </ul>
                                    </li>
                                    <li id="menu-item-356"
                                        class="menu-item menu-item-type-custom menu-item-object-custom menu-item-has-children dropdown menu-item-356 nav-item">
                                        <a href="#" data-toggle="dropdown" aria-expanded="false"
                                            class="dropdown-toggle nav-link" id="menu-item-dropdown-356"><span
                                                itemprop="name">Study VISA</span></a>
                                        <ul class="dropdown-menu" aria-labelledby="menu-item-dropdown-356">
                                            <li id="menu-item-792"
                                                class="menu-item menu-item-type-post_type menu-item-object-visa menu-item-792 nav-item">
                                                <a itemprop="url"
                                                    href="https://www.allenglobalstudies.com/agsd/visa/student-visa-uk/"
                                                    class="dropdown-item"><span itemprop="name">UK Student VISA</span></a></li>
                                            <li id="menu-item-3486"
                                                class="menu-item menu-item-type-post_type menu-item-object-visa menu-item-3486 nav-item">
                                                <a itemprop="url"
                                                    href="https://www.allenglobalstudies.com/agsd/visa/canada-student-visa/"
                                                    class="dropdown-item"><span itemprop="name">Canada Student VISA</span></a>
                                            </li>
                                            <li id="menu-item-3485"
                                                class="menu-item menu-item-type-post_type menu-item-object-visa menu-item-3485 nav-item">
                                                <a itemprop="url"
                                                    href="https://www.allenglobalstudies.com/agsd/visa/australia-student-visa/"
                                                    class="dropdown-item"><span itemprop="name">Australia Student
                                                        VISA</span></a></li>
                                            <li id="menu-item-3708"
                                                class="menu-item menu-item-type-post_type menu-item-object-visa menu-item-3708 nav-item">
                                                <a itemprop="url"
                                                    href="https://www.allenglobalstudies.com/agsd/visa/usa-student-visa/"
                                                    class="dropdown-item"><span itemprop="name">USA Student Visa</span></a></li>
                                        </ul>
                                    </li>
                                    <li id="menu-item-333"
                                        class="menu-item menu-item-type-custom menu-item-object-custom menu-item-has-children dropdown menu-item-333 nav-item">
                                        <a href="#" data-toggle="dropdown" aria-expanded="false"
                                            class="dropdown-toggle nav-link" id="menu-item-dropdown-333"><span
                                                itemprop="name">Our Services</span></a>
                                        <ul class="dropdown-menu" aria-labelledby="menu-item-dropdown-333">
                                            <li id="menu-item-332"
                                                class="menu-item menu-item-type-post_type menu-item-object-services menu-item-332 nav-item">
                                                <a itemprop="url"
                                                    href="https://www.allenglobalstudies.com/agsd/our-services/career-counselling/"
                                                    class="dropdown-item"><span itemprop="name">Career Counselling</span></a>
                                            </li>
                                            <li id="menu-item-3419"
                                                class="menu-item menu-item-type-post_type menu-item-object-services menu-item-3419 nav-item">
                                                <a itemprop="url"
                                                    href="https://www.allenglobalstudies.com/agsd/our-services/test-preparation/"
                                                    class="dropdown-item"><span itemprop="name">Test Preparation</span></a></li>
                                            <li id="menu-item-3420"
                                                class="menu-item menu-item-type-post_type menu-item-object-services menu-item-3420 nav-item">
                                                <a itemprop="url"
                                                    href="https://www.allenglobalstudies.com/agsd/our-services/internship-assistance/"
                                                    class="dropdown-item"><span itemprop="name">Internship Assistance</span></a>
                                            </li>
                                            <li id="menu-item-3421"
                                                class="menu-item menu-item-type-post_type menu-item-object-services menu-item-3421 nav-item">
                                                <a itemprop="url"
                                                    href="https://www.allenglobalstudies.com/agsd/our-services/document-preparation/"
                                                    class="dropdown-item"><span itemprop="name">Document Preparation</span></a>
                                            </li>
                                            <li id="menu-item-499"
                                                class="menu-item menu-item-type-post_type menu-item-object-services menu-item-499 nav-item">
                                                <a itemprop="url"
                                                    href="https://www.allenglobalstudies.com/agsd/our-services/application-process/"
                                                    class="dropdown-item"><span itemprop="name">Application Process</span></a>
                                            </li>
                                            <li id="menu-item-330"
                                                class="menu-item menu-item-type-post_type menu-item-object-services menu-item-330 nav-item">
                                                <a itemprop="url"
                                                    href="https://www.allenglobalstudies.com/agsd/our-services/student-visa-assistance/"
                                                    class="dropdown-item"><span itemprop="name">Student VISA
                                                        Assistance</span></a></li>
                                            <li id="menu-item-498"
                                                class="menu-item menu-item-type-post_type menu-item-object-services menu-item-498 nav-item">
                                                <a itemprop="url"
                                                    href="https://www.allenglobalstudies.com/agsd/our-services/travel-assistance/"
                                                    class="dropdown-item"><span itemprop="name">Travel Assistance</span></a>
                                            </li>
                                        </ul>
                                    </li>
                                    <li id="menu-item-3423"
                                        class="menu-item menu-item-type-custom menu-item-object-custom menu-item-has-children dropdown menu-item-3423 nav-item">
                                        <a href="#" data-toggle="dropdown" aria-expanded="false"
                                            class="dropdown-toggle nav-link" id="menu-item-dropdown-3423"><span
                                                itemprop="name">Courses</span></a>
                                        <ul class="dropdown-menu" aria-labelledby="menu-item-dropdown-3423">
                                            <li id="menu-item-3424"
                                                class="menu-item menu-item-type-post_type menu-item-object-page menu-item-3424 nav-item">
                                                <a itemprop="url"
                                                    href="https://www.allenglobalstudies.com/agsd/long-term-courses-2023-24/"
                                                    class="dropdown-item"><span itemprop="name">Long Term Courses</span></a>
                                            </li>
                                            <li id="menu-item-3602"
                                                class="menu-item menu-item-type-post_type menu-item-object-page menu-item-3602 nav-item">
                                                <a itemprop="url"
                                                    href="https://www.allenglobalstudies.com/agsd/short-term-courses-2023-24/"
                                                    class="dropdown-item"><span itemprop="name">Short Term Courses</span></a>
                                            </li>
                                            <li id="menu-item-3674"
                                                class="menu-item menu-item-type-post_type menu-item-object-page menu-item-3674 nav-item">
                                                <a itemprop="url" href="https://www.allenglobalstudies.com/agsd/workshops/"
                                                    class="dropdown-item"><span itemprop="name">Workshops</span></a></li>
                                        </ul>
                                    </li>
                                    <li id="menu-item-1028"
                                        class="menu-item menu-item-type-custom menu-item-object-custom menu-item-has-children dropdown menu-item-1028 nav-item">
                                        <a href="#" data-toggle="dropdown" aria-expanded="false"
                                            class="dropdown-toggle nav-link" id="menu-item-dropdown-1028"><span
                                                itemprop="name">Test Preparation</span></a>
                                        <ul class="dropdown-menu" aria-labelledby="menu-item-dropdown-1028">
                                            <li id="menu-item-3635"
                                                class="menu-item menu-item-type-custom menu-item-object-custom menu-item-has-children dropdown menu-item-3635 nav-item">
                                                <a itemprop="url" href="#" class="dropdown-item"><span itemprop="name">Aptitude
                                                    Exam</span></a>
                                                <ul class="dropdown-menu" aria-labelledby="menu-item-dropdown-1028">
                                                    <li id="menu-item-3640"
                                                        class="menu-item menu-item-type-post_type menu-item-object-test-preparation menu-item-3640 nav-item">
                                                        <a itemprop="url"
                                                            href="https://www.allenglobalstudies.com/agsd/test-preparation/sat-exam/"
                                                            class="dropdown-item"><span itemprop="name">SAT Exam</span></a></li>
                                                    <li id="menu-item-3646"
                                                        class="menu-item menu-item-type-post_type menu-item-object-test-preparation menu-item-3646 nav-item">
                                                        <a itemprop="url"
                                                            href="https://www.allenglobalstudies.com/agsd/test-preparation/act-exam/"
                                                            class="dropdown-item"><span itemprop="name">ACT Exam</span></a></li>
                                                    <li id="menu-item-3652"
                                                        class="menu-item menu-item-type-post_type menu-item-object-test-preparation menu-item-3652 nav-item">
                                                        <a itemprop="url"
                                                            href="https://www.allenglobalstudies.com/agsd/test-preparation/ap-exam/"
                                                            class="dropdown-item"><span itemprop="name">AP Exam</span></a></li>
                                                    <li id="menu-item-3656"
                                                        class="menu-item menu-item-type-post_type menu-item-object-test-preparation menu-item-3656 nav-item">
                                                        <a itemprop="url"
                                                            href="https://www.allenglobalstudies.com/agsd/test-preparation/gmat-exam/"
                                                            class="dropdown-item"><span itemprop="name">GMAT Exam</span></a>
                                                    </li>
                                                    <li id="menu-item-3661"
                                                        class="menu-item menu-item-type-post_type menu-item-object-test-preparation menu-item-3661 nav-item">
                                                        <a itemprop="url"
                                                            href="https://www.allenglobalstudies.com/agsd/test-preparation/gre-exam/"
                                                            class="dropdown-item"><span itemprop="name">GRE Exam</span></a></li>
                                                </ul>
                                            </li>
                                            <li id="menu-item-3636"
                                                class="menu-item menu-item-type-custom menu-item-object-custom menu-item-has-children dropdown menu-item-3636 nav-item">
                                                <a itemprop="url" href="#" class="dropdown-item"><span itemprop="name">Language
                                                    Exams</span></a>
                                                <ul class="dropdown-menu" aria-labelledby="menu-item-dropdown-1028">
                                                    <li id="menu-item-4402"
                                                        class="menu-item menu-item-type-post_type menu-item-object-test-preparation menu-item-4402 nav-item">
                                                        <a itemprop="url"
                                                            href="https://www.allenglobalstudies.com/agsd/test-preparation/ielts-exam/"
                                                            class="dropdown-item"><span itemprop="name">IELTS Exam</span></a>
                                                    </li>
                                                    <li id="menu-item-4408"
                                                        class="menu-item menu-item-type-post_type menu-item-object-test-preparation menu-item-4408 nav-item">
                                                        <a itemprop="url"
                                                            href="https://www.allenglobalstudies.com/agsd/test-preparation/toefl-exam/"
                                                            class="dropdown-item"><span itemprop="name">TOEFL Exam</span></a>
                                                    </li>
                                                </ul>
                                            </li>
                                        </ul>
                                    </li>
                                    <li id="menu-item-308"
                                        class="menu-item menu-item-type-custom menu-item-object-custom menu-item-has-children dropdown menu-item-308 nav-item">
                                        <a href="#" data-toggle="dropdown" aria-expanded="false"
                                            class="dropdown-toggle nav-link" id="menu-item-dropdown-308"><span
                                                itemprop="name">About Us</span></a>
                                        <ul class="dropdown-menu" aria-labelledby="menu-item-dropdown-308">
                                            <li id="menu-item-297"
                                                class="menu-item menu-item-type-post_type menu-item-object-page menu-item-297 nav-item">
                                                <a itemprop="url" href="https://www.allenglobalstudies.com/agsd/about-us/"
                                                    class="dropdown-item"><span itemprop="name">About Us</span></a></li>
                                            <li id="menu-item-300"
                                                class="menu-item menu-item-type-post_type menu-item-object-page menu-item-300 nav-item">
                                                <a itemprop="url" href="https://www.allenglobalstudies.com/agsd/who-we-are/"
                                                    class="dropdown-item"><span itemprop="name">Who we are?</span></a></li>
                                            <li id="menu-item-303"
                                                class="menu-item menu-item-type-post_type menu-item-object-page menu-item-303 nav-item">
                                                <a itemprop="url"
                                                    href="https://www.allenglobalstudies.com/agsd/vision-and-mission/"
                                                    class="dropdown-item"><span itemprop="name">Vision and Mission</span></a>
                                            </li>
                                        </ul>
                                    </li>
                                    <li id="menu-item-4502"
                                        class="menu-item menu-item-type-custom menu-item-object-custom menu-item-has-children dropdown menu-item-4502 nav-item">
                                        <a href="#" data-toggle="dropdown" aria-expanded="false"
                                            class="dropdown-toggle nav-link" id="menu-item-dropdown-4502"><span
                                                itemprop="name">International Placements</span></a>
                                        <ul class="dropdown-menu" aria-labelledby="menu-item-dropdown-4502">
                                            <li id="menu-item-4503"
                                                class="menu-item menu-item-type-post_type menu-item-object-page menu-item-4503 nav-item">
                                                <a itemprop="url" href="https://www.allenglobalstudies.com/agsd/our-top-admits/"
                                                    class="dropdown-item"><span itemprop="name">Our Top Admits</span></a></li>
                                        </ul>
                                    </li>
                                    <li id="menu-item-218"
                                        class="menu-item menu-item-type-post_type menu-item-object-page menu-item-218 nav-item">
                                        <a itemprop="url" href="https://www.allenglobalstudies.com/agsd/blog/"
                                            class="nav-link"><span itemprop="name">Blog</span></a></li>
                                    <li id="menu-item-699"
                                        class="menu-item menu-item-type-custom menu-item-object-custom menu-item-has-children dropdown menu-item-699 nav-item">
                                        <a href="#" data-toggle="dropdown" aria-expanded="false"
                                            class="dropdown-toggle nav-link" id="menu-item-dropdown-699"><span
                                                itemprop="name">Contact Us</span></a>
                                        <ul class="dropdown-menu" aria-labelledby="menu-item-dropdown-699">
                                            <li id="menu-item-553"
                                                class="menu-item menu-item-type-post_type menu-item-object-page menu-item-553 nav-item">
                                                <a itemprop="url" href="https://www.allenglobalstudies.com/agsd/contact-us/"
                                                    class="dropdown-item"><span itemprop="name">Contact Us</span></a></li>
                                            <li id="menu-item-556"
                                                class="menu-item menu-item-type-post_type menu-item-object-page menu-item-556 nav-item">
                                                <a itemprop="url"
                                                    href="https://www.allenglobalstudies.com/agsd/visit-our-offices/"
                                                    class="dropdown-item"><span itemprop="name">Visit Our Offices</span></a>
                                            </li>
                                            <li id="menu-item-559"
                                                class="menu-item menu-item-type-post_type menu-item-object-page menu-item-559 nav-item">
                                                <a itemprop="url"
                                                    href="https://www.allenglobalstudies.com/agsd/book-an-appointment/"
                                                    class="dropdown-item"><span itemprop="name">Book An Appointment</span></a>
                                            </li>
                                        </ul>
                                    </li>
                                </ul>
                                <ul class="nav navbar-nav ml-auto d-none" data-smartmenus-id="16449253170938967">
                                    <li class="nav-item float-right "><a class="nav-link login-button" href="#">
                                        <p>Login <i class="las la-lock"></i></p>
                                    </a></li>
                                </ul>
                            </div>
                        </div>
                    </nav>
                </header>
                <section>
                    <div class="header-top d-lg-none">
                        <div class="container">
                            <ul class="header-ul">
                                <li><a href="https://www.allen.ac.in/apps2324/ccp/register?leadtype=AGSD" target="_blank"
                                    class="enroll-now">Enroll Now</a></li>
                                <li><a href="https://www.allen.ac.in/apps2324/ccp/Login?leadtype=AGSD" target="_blank"
                                    class="login">Login</a></li>
                                <li><a href="https://www.allen.ac.in/apps2324/formstatus/login" target="_blank"
                                    class="fee-deposition">Fee Deposit</a></li>
                            </ul>
                        </div>
                    </div>

                </section>

            </div>


        </>
    )
}

export default Header;