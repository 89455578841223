import React, { useState, useEffect } from 'react';
import Header from '../components/header';
import axios from "axios";

const StudyAbroad = () => {

    // const [content, setContent] = useState([]);

    // useEffect(() => {
    //     axios
    //         .get("http://localhost:1337/api/abroad")
    //         .then(({ data }) => setContent(data.data))
    //         .catch((error) => console.log(error));
    // }, []);
    return (
        <>
            <Header />
            <div >
                <section class="abroad-top-section">
                    <div class="container">
                        <div class="row">
                            <div class="col-md-6 col-xs-12">
                                <div class="banner-top-content">
                                    <h1>Looking for <span>Quality</span><br />
                                        <span>Education</span> In Abroad?
                                    </h1>
                                    <h2>“Aim to become a part of the world’s<br />
                                        best university systems”</h2>
                                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing Donec felis, suscipit urna nulla at
                                        tincidunt feugiat vulputate. Ante facilisis face pellentesque quis egestas metus.</p>
                                    <div class="top-btn">
                                        <a href="#">Get Started</a>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-6 col-xs-12">
                                <div class="banner-top-image">
                                    <img src="/study-abroad/header-image.png" />
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                <section class="abroad-section1">
                    <div class="container">
                        <div class="abroad-top-links">
                            <div class="links-item">
                                <a href="#top-countries" class="full-link">&nbsp;</a>
                                <div class="link-icon"><img src="/study-abroad/link-icon1.png" /></div>
                                <p>Top Countries</p>
                            </div>
                            <div class="links-item">
                                <a href="#test-preparation" class="full-link">&nbsp;</a>
                                <div class="link-icon"><img src="/study-abroad/link-icon2.png" /></div>
                                <p>Test Preparation</p>
                            </div>
                            <div class="links-item">
                                <a href="#student-visa" class="full-link">&nbsp;</a>
                                <div class="link-icon"><img src="/study-abroad/link-icon3.png" /></div>
                                <p>Student Visa</p>
                            </div>
                            <div class="links-item">
                                <a href="#documentations" class="full-link">&nbsp;</a>
                                <div class="link-icon"><img src="/study-abroad/link-icon4.png" /></div>
                                <p>Documentations</p>
                            </div>
                            <div class="links-item">
                                <a href="#videos-gallery" class="full-link">&nbsp;</a>
                                <div class="link-icon"><img src="/study-abroad/link-icon5.png" /></div>
                                <p>Videos</p>
                            </div>
                            <div class="links-item">
                                <a href="#blogs" class="full-link">&nbsp;</a>
                                <div class="link-icon"><img src="/study-abroad/link-icon6.png" /></div>
                                <p>Blogs</p>
                            </div>
                            <div class="links-item border-none">
                                <a href="#faq" class="full-link">&nbsp;</a>
                                <div class="link-icon"><img src="/study-abroad/link-icon7.png" /></div>
                                <p>FAQ’s</p>
                            </div>
                        </div>
                    </div>
                </section>



                <section class="abroad-section2 display-inline" id="top-countries">
                    <div class="container">
                        <div class="abroad-heading">
                            <h2>Top Countries to Study <span>Abroad</span></h2>
                            <p>Lorem ipsum dolor sit amet, consectetur adipiscing Donec felis, suscipit urna nulla at tincidunt
                                feugiat vulputate. Ante facilisis face pellentesque quis egestas metus.</p>
                        </div>
                        <div class="countries-section">
                            <div class="row">
                                <div class="col-md-3 col-sm-6 col-xs-12">
                                    <div class="countries-block">
                                        <div class="country-img">
                                            <img src="/study-abroad/country-image01.png" />
                                        </div>
                                        <div class="country-icon">
                                            <span><img src="/study-abroad/country-icon01.png" /></span>
                                        </div>
                                        <h2>United States</h2>
                                        <div class="more-btn">
                                            <a href="#">Read More</a>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-3 col-sm-6 col-xs-12">
                                    <div class="countries-block">
                                        <div class="country-img">
                                            <img src="/study-abroad/country-image02.png" />
                                        </div>
                                        <div class="country-icon">
                                            <span><img src="/study-abroad/country-icon02.png" /></span>
                                        </div>
                                        <h2>Australia</h2>
                                        <div class="more-btn">
                                            <a href="#">Read More</a>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-3 col-sm-6 col-xs-12">
                                    <div class="countries-block">
                                        <div class="country-img">
                                            <img src="/study-abroad/country-image03.png" />
                                        </div>
                                        <div class="country-icon">
                                            <span><img src="/study-abroad/country-icon03.png" /></span>
                                        </div>
                                        <h2>Canada</h2>
                                        <div class="more-btn">
                                            <a href="#">Read More</a>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-3 col-sm-6 col-xs-12">
                                    <div class="countries-block">
                                        <div class="country-img">
                                            <img src="/study-abroad/country-image04.png" />
                                        </div>
                                        <div class="country-icon">
                                            <span><img src="/study-abroad/country-icon04.png" /></span>
                                        </div>
                                        <h2>United Kingdom</h2>
                                        <div class="more-btn">
                                            <a href="#">Read More</a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                <section class="abroad-section3 display-inline" id="test-preparation">
                    <div class="abroad-heading">
                        <div class="container">
                            <h2>Test <span>Preparations</span></h2>
                            <p>Lorem ipsum dolor sit amet, consectetur adipiscing Donec felis, suscipit urna nulla at tincidunt
                                feugiat vulputate. Ante facilisis face pellentesque quis egestas metus.</p>
                        </div>
                    </div>
                    <div class="preparation-section display-inline">
                        <div class="container">
                            <div class="preparation-block owl-carousel owl-theme" id="preparation-slider">
                                <div class="preparation-box">
                                    <div class="preparation-img">
                                        <img src="/study-abroad/image01.png" />
                                    </div>
                                    <h2>TOEFL Test</h2>
                                    <div class="preparation-cnt">
                                        <h3><a href="#">TOEFL Test</a></h3>
                                        <p>The Human Rights and Democracy Study Visa Programms</p>
                                    </div>
                                </div>
                                <div class="preparation-box">
                                    <div class="preparation-img">
                                        <img src="/study-abroad/image02.png" />
                                    </div>
                                    <h2>PTE Coaching</h2>
                                    <div class="preparation-cnt">
                                        <h3><a href="#">PTE Coaching</a></h3>
                                        <p>The Human Rights and Democracy Study Visa Programms</p>
                                    </div>
                                </div>
                                <div class="preparation-box">
                                    <div class="preparation-img">
                                        <img src="/tudy-abroad/image03.png" />
                                    </div>
                                    <h2>ACT Exam</h2>
                                    <div class="preparation-cnt">
                                        <h3><a href="#">ACT Exam</a></h3>
                                        <p>The Human Rights and Democracy Study Visa Programms</p>
                                    </div>
                                </div>
                                <div class="preparation-box">
                                    <div class="preparation-img">
                                        <img src="/study-abroad/image01.png" />
                                    </div>
                                    <h2>TOEFL Test</h2>
                                    <div class="preparation-cnt">
                                        <h3><a href="#">TOEFL Test</a></h3>
                                        <p>The Human Rights and Democracy Study Visa Programms</p>
                                    </div>
                                </div>
                                <div class="preparation-box">
                                    <div class="preparation-img">
                                        <img src="/study-abroad/image02.png" />
                                    </div>
                                    <h2>PTE Coaching</h2>
                                    <div class="preparation-cnt">
                                        <h3><a href="#">PTE Coaching</a></h3>
                                        <p>The Human Rights and Democracy Study Visa Programms</p>
                                    </div>
                                </div>
                                <div class="preparation-box">
                                    <div class="preparation-img">
                                        <img src="study-abroad/image03.png" />
                                    </div>
                                    <h2>ACT Exam</h2>
                                    <div class="preparation-cnt">
                                        <h3><a href="#">ACT Exam</a></h3>
                                        <p>The Human Rights and Democracy Study Visa Programms</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>

        </>
    )
}

export default StudyAbroad