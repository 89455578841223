import React, { useState, useEffect } from 'react';
import axios from "axios";
import { useParams, useNavigate } from 'react-router-dom';

const Aboard = () => {
    const { slug } = useParams();
    //const navigate = useNavigate();
    const [page, setPage] = useState(null);


    useEffect(() => {
        axios
            .get(`http://localhost:1337/api/pages/${slug}`)
            .then(({ data }) => {
                console.log("Response from server:", data);
                if (data) {
                    console.log("Page data:", data);
                    setPage(data.data);
                } else {
                    console.log("No data found for slug:", slug);
                }
            })
            .catch((error) => console.log("Error fetching data:", error));
    }, [slug]);

    if (!page) {
        return <div>Loading...</div>;
    }

    return (
        <>
            <div key={page.slug}>
                <div className="benefitPlan">
                    <h3>Most Comprehensive Employee Benefit Plan</h3>
                    <img src={page.image} />
                </div>
                <div>
                    <div id={page.id} style={{ display: "flex" }}>
                        <img src={page.image} style={{ width: 50, height: 50 }} className="benefitPlan-icon" />
                        <div>
                            <h4>{page.attributes.title}</h4>
                            <p>{page.attributes.description}</p>
                        </div>
                    </div>
                </div>

            </div>
        </>
    )
}



export default Aboard