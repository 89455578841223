import React, { useEffect, useState } from 'react';
import { Layout, Menu, Button, Affix } from 'antd';
import {
    DashboardOutlined,

} from '@ant-design/icons';
//import { useHistory } from "react-router-dom";

const { Header, Sider } = Layout;

const AppLayout = ({ children }) => {

    // const history = useHistory()
    // const logout = () => {
    //     localStorage.removeItem('token')
    //     localStorage.removeItem('role')
    //     history.push('/')
    // }

    // useEffect(() => {
    //     const role = localStorage.getItem('role')
    //     if (role == 'admin') {
    //         setIsAdmin(true)
    //     }
    // }, [])

    const menuItems = [
        {
            title: 'Dashboard',
            path: '/components/dashboard',
            icon: <DashboardOutlined />
        },

    ]

    return (
        <div className="dashboardSection">
            <Layout>
                <Affix offsetTop={0} >
                    <Sider
                        breakpoint="xl"
                        collapsedWidth="0"
                        width={230}

                    >

                        <div className="logo" style={{ padding: "20px 10px" }}>
                            <img src="/images/allen-global-logo.png" alt='allen-global-logo' width="150px" />
                        </div>

                        <Menu
                            theme='dark'
                            mode="inline"
                            defaultSelectedKeys={[window.location.pathname]}
                            defaultOpenKeys={['sub1']}
                            style={{ height: '100vh' }}
                        >
                            {menuItems.map((item, subm) =>
                                item.subMenu ?
                                    <Menu.SubMenu key={subm} title={item.title} icon={item.icon} style={{ marginLeft: "-15px" }}>
                                        {item.subMenu.map(el =>
                                            <Menu.Item key={el.path}  >
                                                <a href={el.path}>{el.title}</a>
                                            </Menu.Item>
                                        )}
                                    </Menu.SubMenu>
                                    :
                                    <Menu.Item key={item.path} title={item.title} icon={item.icon} style={{ paddingLeft: "10px" }}>
                                        <a href={item.path}>{item.title}</a>
                                    </Menu.Item>
                            )}
                        </Menu>
                    </Sider>
                </Affix>
                <Layout className="site-layout">
                    <Affix offsetTop={0} >
                        <Header style={{ alignItems: "center", justifyContent: "flex-end", boxShadow: '2px 2px 6px 1px #e4e4e4' }} className="site-layout-background d-flex" >
                            <Button className="float-right mt-5">
                                Logout
                            </Button>
                        </Header>
                    </Affix>
                    {children}

                </Layout>
            </Layout>
        </div>
    );
}

export default AppLayout