
import './App.css';
import { Route, Routes, BrowserRouter } from 'react-router-dom'
import Login from './components/login';
import Dashboard from './components/dashboard';
import AppLayout from './components/app-layout';
import Page from './components/page';
import Aboard from './components/aboard';
import Header from './components/header';
import StudyAbroad from './pages/study-abroad';
import UniversityInfo from './universities/content';
import Footer from './components/footer';
import './css/style.css';
import './css/responsive.css'


function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path='/' element={<Login />} />
        <Route path='/components/dashboard' element={<Dashboard />} />
        <Route path='/components/app-layout' element={<AppLayout />} />
        <Route path='/components/page' element={<Page />} />
        <Route path='/components/aboard/:slug' element={<Aboard />} />
        <Route path='/components/header' element={<Header />} />
        <Route path='/pages/study-abroad' element={<StudyAbroad />} />
        <Route path='/universities/content/:slug' element={<UniversityInfo />} />
        <Route path='/components/footer' element={<Footer />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
