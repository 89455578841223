import React, { useState, useEffect } from 'react';
import axios from "axios";
import { useParams, useNavigate } from 'react-router-dom';
import Header from '../components/header';
import '../css/style.css';
import Footer from '../components/footer';


const UniversityInfo = () => {
    const { slug } = useParams();
    //const navigate = useNavigate();
    const [content, setContent] = useState(null);
    const [accordionData, setAccordionData] = useState([]);
    const [faqData, setFaqData] = useState([]);
    const [sliderData, setSliderData] = useState([]);

    useEffect(() => {
        console.log("Slug in component:", slug);
        axios
            .get(`http://localhost:1337/api/pages/${slug}?populate=*`)
            .then(({ data }) => {
                console.log("API Response:", data);
                // Rest of your code to process the data
                setContent(data.data);
                setAccordionData(data.data.attributes.accordion.data)
                setFaqData(data.data.attributes.faq.data)
                setSliderData(data.data.attributes.slider.data)

            })
            .catch((error) => console.error("Error fetching data:", error));
    }, [slug]);

    if (!content) {
        return <div>Loading...</div>;

    }

    return (
        <>
            <div key={content.slug}>
                <Header />


                <div id={content.id} className="inner-content-section">
                    <section className="inner-top-section display-inline">
                        <div className="container">
                            <div className="row">
                                <div className="col-md-6 col-xs-12">
                                    <div className="banner-top-content">
                                        <h1>{content.attributes.main_heading}<br />
                                            <span>{content.attributes.country}</span><br />
                                        </h1>
                                        {/* <div dangerouslySetInnerHTML={{ __html: content.attributes.main_heading }} /> */}
                                    </div>

                                </div>
                                <div className="col-md-6 col-xs-12">
                                    <div className="banner-top-image">
                                        <img src="/images/header-image03.png" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>

                    <section className="content-section1 display-inline">

                        <div className="container">
                            <div className="row">
                                <div className="col-md-7 col-xs-12">
                                    <div className="content-block display-inline">

                                        <div dangerouslySetInnerHTML={{ __html: content.attributes.paragraph1 }} />

                                    </div>
                                    <div className="content-block display-inline bg-color">
                                        <div dangerouslySetInnerHTML={{ __html: content.attributes.paragraph2 }} />


                                        {accordionData.map((item, index) => (
                                            <div className="accordion">
                                                <div className={`accordion-item ${index === 0 ? 'show' : 'hide'}`} key={index}>

                                                    {/* <div className="accordion-item" key={index}> */}
                                                    <h2 className="accordion-header">
                                                        {item.attributes.title}
                                                    </h2>
                                                    <div className="accordion-body" style={{ display: index === 0 ? 'block' : 'none' }}>
                                                        <p>{item.attributes.description}</p>
                                                    </div>
                                                    {/* </div> */}

                                                </div>
                                            </div>
                                        ))}
                                        {/* <p>{content.attributes.des2}</p> */}
                                        <div dangerouslySetInnerHTML={{ __html: content.attributes.paragraph3 }} />

                                    </div>
                                </div>
                                <div className="col-md-5 col-xs-12">
                                    <aside>
                                        <div className="form-outer">
                                            <h2><span>Quick Enquiry</span></h2>
                                            <div className="enquiry-form">
                                                <div className="form-group">
                                                    <input type="text" className="form-control" id="" placeholder="Name" />
                                                </div>
                                                <div className="form-group">
                                                    <input type="text" className="form-control" id="" placeholder="Mobile Number" />
                                                </div>
                                                <div className="row">
                                                    <div className="col-md-6">
                                                        <div className="form-group">
                                                            <input type="text" className="form-control" id="" placeholder="Email Address" />
                                                        </div>
                                                    </div>
                                                    <div className="col-md-6">
                                                        <div className="form-group">
                                                            <select className="form-control" id="grade" name="grade">
                                                                <option value="" disabled selected>Select Grade</option>
                                                                <option value="Grade1">Grade 1</option>
                                                                <option value="Grade2">Grade 2</option>
                                                            </select>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="col-md-6">
                                                        <div className="form-group">
                                                            <select className="form-control" id="state" name="state">
                                                                <option value="" disabled selected>Select State</option>
                                                                <option value="raj">Rajasthan</option>
                                                                <option value="mp">Mp</option>
                                                            </select>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-6">
                                                        <div className="form-group">
                                                            <select className="form-control" id="city" name="city">
                                                                <option value="" disabled selected>Select City</option>
                                                                <option value="kota">Kota</option>
                                                                <option value="jaipur">Jaipur</option>
                                                            </select>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="col-md-6 col-6">
                                                        <div className="form-group custom-control custom-radio">
                                                            <input type="radio" className="custom-control-input" id="under-graduate" name="graduate" />
                                                            <label className="custom-control-label" htmlFor="under-graduate">Under Graduate</label>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-6 col-6">
                                                        <div className="form-group custom-control custom-radio">
                                                            <input type="radio" className="custom-control-input" id="post-graduate" name="graduate" />
                                                            <label className="custom-control-label" htmlFor="post-graduate">Post Graduate</label>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="form-group">
                                                    <textarea className="form-control" id="message" placeholder="Message" rows="3"></textarea>
                                                </div>
                                                <div className="form-group">
                                                    <div className="custom-control custom-checkbox">
                                                        <input type="checkbox" className="custom-control-input" id="customCheck" name="example1" />
                                                        <label className="custom-control-label" htmlFor="customCheck">I agree to Terms & Conditions</label>
                                                    </div>
                                                </div>
                                                <div className="form-group btn-outer">
                                                    <button type="submit" className="submit-btn">Submit</button>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="category-list-outer">
                                            <div dangerouslySetInnerHTML={{ __html: content.attributes.categories }} />

                                        </div>
                                        <div className="blog-list-outer">
                                            <h2>Popular Blogs</h2>
                                            <div className="blogs">
                                                <div className="blog-img"><img src="/images/blog-image01.png" alt="" /></div>
                                                <div className="blog-cnt">
                                                    <h4><a href='#'>Lorem ipsum dolor sit amet, consectetur adipiscing elit...</a></h4>
                                                    <div className="blog-date"><i className="fa fa-calendar" aria-hidden="true"></i> 26 Sep 2023</div>
                                                    <div className="blog-date right">. <i className="fa fa-comment-o" aria-hidden="true"></i> 10</div>
                                                </div>
                                            </div>
                                            <div className="blogs">
                                                <div className="blog-img"><img src="/images/blog-image02.png" alt="" /></div>
                                                <div className="blog-cnt">
                                                    <h4>Lorem ipsum dolor sit amet, consectetur adipiscing elit...</h4>
                                                    <div className="blog-date"><i className="fa fa-calendar" aria-hidden="true"></i> 26 Sep 2023</div>
                                                    <div className="blog-date right">. <i className="fa fa-comment-o" aria-hidden="true"></i> 10</div>
                                                </div>
                                            </div>
                                            <div className="blogs">
                                                <div className="blog-img"><img src="/images/blog-image01.png" alt="" /></div>
                                                <div className="blog-cnt">
                                                    <h4>Lorem ipsum dolor sit amet, consectetur adipiscing elit...</h4>
                                                    <div className="blog-date"><i className="fa fa-calendar" aria-hidden="true"></i> 26 Sep 2023</div>
                                                    <div className="blog-date right">. <i className="fa fa-comment-o" aria-hidden="true"></i> 10</div>
                                                </div>
                                            </div>
                                        </div>
                                    </aside>
                                </div>
                            </div>
                            <div className="content-section2 display-inline">
                                <div dangerouslySetInnerHTML={{ __html: content.attributes.paragraph4 }} />

                            </div>
                        </div>
                    </section>

                    <section className="add-section display-inline">
                        <div className="add-block display-inline">
                            {/* <img src="/images/advertisement.jpg" alt="" /> */}
                            <div dangerouslySetInnerHTML={{ __html: content.attributes.advertisement }} />
                        </div>
                    </section>


                    <section className="content-section2 display-inline">
                        <div className="container">
                            <div dangerouslySetInnerHTML={{ __html: content.attributes.paragraph5 }} />

                        </div>
                    </section>


                    <section className="inner-faq-section">
                        <div className="left-block">
                            <img src="/images/image04.jpg" alt="" />
                        </div>
                        <div className="right-block">
                            <div className="inner-faq">
                                <h2>Frequently Asked <span>Questions</span></h2>


                                {faqData.map((item, index) => (
                                    <div className="accordion">
                                        <div className={`accordion-item ${index === 0 ? 'show' : 'hide'}`} key={index}>
                                            <h2 className="accordion-header" data-question-number={index + 1}>
                                                {item.attributes.ques}
                                            </h2>
                                            <div className="accordion-body" style={{ display: index === 0 ? 'block' : 'none' }}>
                                                <p>{item.attributes.ans}</p>
                                            </div>
                                        </div>
                                    </div>
                                ))}


                            </div>
                        </div>
                    </section>

                    <section className="related-post-section display-inline">
                        <div className="container">
                            <h2>Related <span>Post</span></h2>
                            <div dangerouslySetInnerHTML={{ __html: content.attributes.post }} />

                            <div className="post-block owl-carousel owl-theme" id="post_slider">
                                {sliderData.map((item, index) => (
                                    <div className="post-item" key={index}>
                                        <div className="post-img">
                                            <div dangerouslySetInnerHTML={{ __html: item.attributes.image }} />
                                        </div>
                                        <div className="post-cnt">
                                            <h4>{item.attributes.title}</h4>
                                            <p>{item.attributes.description}</p>
                                            <div className="view-more-btn">
                                                <a href="#">View More</a>
                                            </div>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>

                    </section >
                </div>



                <Footer />
            </div>
        </>
    )
}

export default UniversityInfo